import BitcoinService from '../services/BitcoinService';

export async function fetchScriptPubKey(txId, vout) {
  let url = `https://mempool.space/api/tx/${txId}`;
  if (!BitcoinService.useMainnet) {
    url = `https://mempool.space/testnet/api/tx/${txId}`;
  }

  const response = await fetch(url);
  if (!response.ok) {
    throw new Error('Error fetching transaction details from mempool.space API.');
  }
  const transaction = await response.json();
  if (transaction.vout && transaction.vout.length > vout && transaction.vout[vout].scriptpubkey) {
    return transaction.vout[vout].scriptpubkey;
  } else {
    throw new Error('scriptPubKey not found for the given vout.');
  }
}
