import React, { useState, useEffect } from 'react';
import LogoImage from '../images/MHinscriptions_logo-01-RED-transparent.png';
import FontAnimImage from '../images/font-anim.gif';

const IntroScreen = ({ onIntroEnd }) => {
  const [elementFadeClass, setElementFadeClass] = useState('hidden');
  const [backgroundFadeClass, setBackgroundFadeClass] = useState('');
  const [trDarkClass] = useState('tr-dark'); // Start with tr-dark class only

  useEffect(() => {
    setElementFadeClass('hidden');
    setBackgroundFadeClass('');

    const itemsTimer = setTimeout(() => {
      setElementFadeClass('fade-in');
    });

    return () => {
      clearTimeout(itemsTimer);
    };
  }, []);

  const handleClick = () => {
    // Apply fade-out class to both logo and font animation
    setElementFadeClass('fade-out');
    setBackgroundFadeClass('fade-out');

    // Ensure onIntroEnd is called after the fade-out animation completes
    setTimeout(onIntroEnd, 500);
  };

  return (
    <div className={`pre-loader ${backgroundFadeClass}`} onClick={handleClick}>
      <div className={`tr-dark ${trDarkClass}`}></div>
      <div className="loader-wrapper">
        <img src={LogoImage} alt="Logo" className={`logo ${elementFadeClass}`} />
        <img src={FontAnimImage} alt="Font Animation" className={`font-animation ${elementFadeClass}`} />
      </div>
    </div>
  );
};

export default IntroScreen;
