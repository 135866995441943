import './polyfills';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import '@rainbow-me/rainbowkit/styles.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { getDefaultConfig, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { WagmiProvider } from 'wagmi';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { mainnet } from 'wagmi/chains';
import { WalletStandardProvider } from '@wallet-standard/react';

// console.log('test window buffer', Buffer);
// window.Buffer = Buffer;

const walletConnectId = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID;

const config = getDefaultConfig({
  appName: 'Mutant Hound Ordinals',
  projectId: walletConnectId,
  chains: [mainnet],
});

const queryClient = new QueryClient();
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider>
          <WalletStandardProvider>
            <Router>
              <App />
            </Router>
          </WalletStandardProvider>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  </React.StrictMode>
);
reportWebVitals();
