import BitcoinService from '../services/BitcoinService';
const { Psbt, networks } = require('bitcoinjs-lib');
const { fetchScriptPubKey } = require('./fetchScriptPubKey');

async function fetchRecommendedFees() {
  let url = 'https://mempool.space/api/v1/fees/recommended';
  if (!BitcoinService.useMainnet) {
    url = 'https://mempool.space/testnet/api/v1/fees/recommended';
  }
  console.log('fetchRecommendedFees', url);
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error('Error fetching recommended fees');
  }
  const fees = await response.json();
  return fees;
}

export async function createPSBT({ selectedUTXOs, recipientAddress, changeAddress, amountToSend, totalValue }) {
  console.log('createPSBT', selectedUTXOs, recipientAddress, changeAddress, amountToSend);
  const psbt = new Psbt({ network: networks.bitcoin });

  const recommendedFees = await fetchRecommendedFees();
  const feePerByte = recommendedFees.hourFee;
  // The estimated size needs to be adjusted based on the number of inputs and outputs
  const estimatedTxSize = 10 + selectedUTXOs.length * 148 + 2 * 34; // Very basic estimation
  const transactionFee = feePerByte * estimatedTxSize;
  console.log('Estimated transaction fee:', transactionFee);

  for (const utxo of selectedUTXOs) {
    const scriptPubKey = await fetchScriptPubKey(utxo.txid, utxo.vout);
    psbt.addInput({
      hash: utxo.txid,
      index: utxo.vout,
      witnessUtxo: {
        script: Buffer.from(scriptPubKey, 'hex'),
        value: utxo.value,
      },
    });
  }

  psbt.addOutput({
    address: recipientAddress,
    value: amountToSend,
  });

  const changeValue = totalValue - amountToSend - transactionFee;
  if (changeValue > 0) {
    psbt.addOutput({
      address: changeAddress,
      value: changeValue,
    });
  }

  return psbt.toBase64();
}
