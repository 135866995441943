import React, { createContext, useContext, useState } from 'react';
import { useMagicEdenProvider } from '../hooks/useMagicEdenProvider'; // Adjust the import path as needed

// Creating the context
export const ConnectionStatusContext = createContext(null);

// Custom hook to use the context
export const useConnectionStatus = () => {
  const context = useContext(ConnectionStatusContext);
  if (!context) {
    throw new Error('useConnectionStatus must be used within a ConnectionStatusProvider');
  }
  return context;
};

// Provider component
export const ConnectionStatusProvider = ({ children }) => {
  const provider = useMagicEdenProvider();
  const [isConnected, setIsConnected] = useState(false);
  const [address, setAddress] = useState();

  const connect = async () => {
    if (!provider) return;
    try {
      const signerAddress = await provider.send('eth_requestAccounts', []);
      setIsConnected(true);
      setAddress(signerAddress);
    } catch (err) {
      console.error(err);
    }
  };

  const value = { isConnected, provider, address, connect };

  return <ConnectionStatusContext.Provider value={value}>{children}</ConnectionStatusContext.Provider>;
};
