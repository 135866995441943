import React, { useState, useEffect } from 'react';
import '../css/normalize.css';
import '../css/webflow.css';
import '../css/ordinals.css';
import FooterComponent from '../components/FooterComponent';
import BitcoinService from '../services/BitcoinService';
import BTCWalletManagerComponent from '../components/BTCWalletManagerComponent';
import RegisterService from '../services/RegisterService';
import ErrorPopup from '../popups/ErrorPopup';

const HomeScreen = ({ animationClass, onAction, onShowFAQ, onTrinity, onGenealogy }) => {
  const [walletPopupVisible, showWalletPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [ticketId, setTicketId] = useState('');
  const [paymentWalletAddress, setPaymentWalletAddress] = useState('');
  const [ordinalWalletAddress, setOrdinalWalletAddress] = useState('');
  const [showTicketPopup, setShowTicketPopup] = useState(false);

  const verifyWallet = () => {
    showWalletPopup(true);
  };

  const handleWalletConnectionSuccess = async (walletAddresses, walletType) => {
    try {
      console.log('Wallet connected:', walletAddresses);
      const paymentWallet = walletAddresses.find((wallet) => wallet.type === 'payment');
      const ordinalWallet = walletAddresses.find((wallet) => (wallet.type === 'ordinals' || wallet.type === 'ordinal'));
      setOrdinalWalletAddress(ordinalWallet.address);
      setPaymentWalletAddress(paymentWallet.address);
      showWalletPopup(false);

      const message = 'I am confirming my wallet for support tickets.';
      console.log('Bitcoin message to sign:', paymentWallet, ordinalWallet, message, walletType);
      const signature = await BitcoinService.signMessage({
        address: ordinalWallet.address, 
        message: message, 
        walletType: walletType
      });
      console.log('Bitcoin message signed:', signature);

      const ticketId = await RegisterService.registerSupportTicket(paymentWallet.address, ordinalWallet.address, signature);
      setTicketId(ticketId);
      console.log('Support ticket registered:', ticketId);
    } catch (error) {
      console.error('Error handling wallet connection:', error);
      setErrorMessage(error.message || 'Failed to process the wallet connection and ticket registration.');
    }
  };

  const handleWalletDisconnection = () => {
    showWalletPopup(false);
  }

  const handleOnWalletPopupClose = () => {
    showWalletPopup(false);
  }

  useEffect(() => {
    const showTicketPanel = ticketId && paymentWalletAddress && ordinalWalletAddress;
    console.log('Wallet popup visible:', showTicketPanel, ticketId, paymentWalletAddress, ordinalWalletAddress);
      setShowTicketPopup(showTicketPanel);
  }, [ticketId, paymentWalletAddress, ordinalWalletAddress]);

  return (
    <div className={`content-frame ${animationClass}`}>
      <div id="ordinals" className="home-section">
        <ErrorPopup errorMessage={errorMessage} onClose={() => setErrorMessage('')} />
        {walletPopupVisible && (
          <BTCWalletManagerComponent
            onWalletConnected={handleWalletConnectionSuccess}
            onWalletDisconnected={handleWalletDisconnection}
            onClose={handleOnWalletPopupClose}
          />
        )}
        
        {/* Pop up for ticket */}
        {showTicketPopup && ticketId && (
          <div className="btc-wallet-wrapper">
              <div className="wallet-box">
              <h2 className="h2">VERIFICATION REFERENCE</h2>
              <p className="paragraph red4">{ticketId}</p>
              <h2 className="h2">Payment wallet address</h2>
              <p className="paragraph red4">{paymentWalletAddress}</p>
              <h2 className="h2">Ordinal wallet address</h2>
              <p className="paragraph red4">{ordinalWalletAddress}</p>
              </div>
          </div>
        )}
        <div className="home-box quickin" onClick={verifyWallet}>
          <div className="current-box">
            <h2 className="h2">VERIFY WALLET</h2>
          </div>
        </div>
      </div>
      <FooterComponent
        activeButton="home"
        onIdentitySourceClick={() => console.log('Identity Source Clicked')}
        onShowFAQ={onShowFAQ}
        onTrinityClick={onTrinity}
        onGenealogyClick={onGenealogy}
      />
    </div>
  );
};

export default HomeScreen;
