// RegisterService.js
import axios from 'axios';
import BitcoinService from './BitcoinService';

const RegisterService = {
  requestSignatureAndRegister: async (
    ethAddress,
    ethWalletConnected,
    btcWalletConnected,
    btcAddress,
    btcWalletType,
    amount,
    signMessageAsync,
    setIsJoinRequestInProgress,
    setErrorMessage,
    onJoined,
    bestTier
  ) => {
    if (!ethWalletConnected && !btcWalletConnected) {
      setErrorMessage('Please connect at least one wallet to proceed with registration.');
      console.error('No wallet connected.');
      return;
    }

    const ethMessage = `I am registering my address ${ethAddress} to join the whitelist.\n\nThis signature request will not trigger a blockchain transaction or cost any gas fees.`;
    const btcMessage = `I am registering my ordinal address ${btcAddress} to join the whitelist.\n\nThis signature request will not trigger a transaction or cost any fees.`;
    try {
      const ethSignature =
        ethWalletConnected && signMessageAsync ? await signMessageAsync({ message: ethMessage }) : '';
      console.log('Ethereum message signed:', ethSignature);

      const btcSignature = btcWalletConnected
        ? await BitcoinService.signMessage({
            address: btcAddress,
            message: btcMessage,
            walletType: btcWalletType,
          })
        : '';
      console.log('Bitcoin message signed:', btcSignature);

      await RegisterService.handleRegistration(
        ethAddress,
        btcAddress,
        amount,
        ethSignature,
        btcSignature,
        setIsJoinRequestInProgress,
        setErrorMessage,
        onJoined,
        bestTier
      );
    } catch (error) {
      console.error('Failed to sign the message:', error);
      const message = error.response?.data?.message || 'Registration failed. Please try again.';
      const statusCode = error.response?.status; // Get the HTTP status code

      // Assuming setErrorMessage can handle two parameters: message and code
      setErrorMessage(message, statusCode);
    }
  },

  handleRegistration: async (
    ethAddress,
    btcAddress,
    amount,
    ethSignature,
    btcSignature,
    setIsJoinRequestInProgress,
    setErrorMessage,
    onJoined,
    bestTier
  ) => {
    if (setIsJoinRequestInProgress) {
      setIsJoinRequestInProgress(true);
    }

    const data = {
      ordinals: amount,
      tier: bestTier,
      ethereumAddress: ethAddress,
      ethSignature: ethSignature,
      btcAddress: btcAddress,
      btcSignature: btcSignature,
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_WEB_SERVER}/register`, data, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
        },
      });
      console.log('Registration successful:', response.data);
      onJoined?.(response.data);
    } catch (error) {
      console.error('Registration failed:', error);
      const message = error.response?.data?.message || 'Registration failed. Please try again.';
      const statusCode = error.response?.status; // Get the HTTP status code

      // Assuming setErrorMessage can handle two parameters: message and code
      setErrorMessage(message, statusCode);
    } finally {
      if (setIsJoinRequestInProgress) {
        setIsJoinRequestInProgress(false);
      }
    }
  },
  requestEthSignature: async (ethAddress, signMessageAsync) => {
    const ethMessage = `I am authorizing this order with my address ${ethAddress} to confirm my purchase. \n\n This signature request will not trigger a blockchain transaction or cost any gas fees.`;
    try {
      if (!signMessageAsync) {
        throw new Error('Ethereum signing function is not available.');
      }
      const ethSignature = await signMessageAsync({ message: ethMessage });
      console.log('Ethereum message signed:', ethSignature);
      return ethSignature;
    } catch (error) {
      console.error('Failed to sign the Ethereum message:', error);
      throw error;
    }
  },
  fetchGlobalSettings: async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_WEB_SERVER}/getGlobalSettings`);
      return response.data;
    } catch (error) {
      console.error('Failed to fetch global settings:', error);
    }
  },
  getRemainingMintable: async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_WEB_SERVER}/getRemainingMintable`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
        },
      });
      return response.data.remainingMintable;
    } catch (error) {
      console.error('Failed to fetch total available:', error);
    }
  },
  getTierAndAllowance: async (ethAddress, btcAddress) => {
    try {
      const data = {
        ethAddress: ethAddress,
        btcAddress: btcAddress,
      };
      const response = await axios.post(`${process.env.REACT_APP_WEB_SERVER}/getTierAndAllowance`, data, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Failed to fetch global settings:', error);
    }
  },
  registerSupportTicket: async (paymentWallet, ordinalWallet, signature) => {
    try {
      const data = {
        paymentWallet: paymentWallet,
        ordinalWallet: ordinalWallet,
        signature: signature
      };
      const response = await axios.post(`${process.env.REACT_APP_WEB_SERVER}/registerSupportTicket`, data, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
        },
      });
      return response.data.ticketId;
    } catch (error) {
      console.error('Error registering the support ticket:', error);
      throw error;
    }
  },
};

export default RegisterService;
