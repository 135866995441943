import React, { useState, useEffect, useRef } from 'react';
import introAudio from './audio/Ordinals.mp3';
import validateAudio from './audio/validateAudio.mp3';
import roomImage from './images/roomL1.png';
import roomImage1 from './images/roomL2.png';
import './css/normalize.css';
import './css/webflow.css';
import './css/ordinals.css';
import { ConnectionStatusProvider } from './contexts/ConnectionStatusContext';
import RegisterService from './services/RegisterService';
import LogoImage from './images/MHinscriptions_logo-01-RED-transparent.png';

import IntroScreen from './screens/IntroScreen';
import NavbarComponent from './components/NavbarComponent';
import HomeScreen from './screens/HomeScreen';
import TrinityScreen from './screens/TrinityScreen';
import GenealogyScreen from './screens/GenealogyScreen';
import VideoHome from './images/MHIhome.webm';

import FAQpopup from './popups/FAQpopup';
import PausedPopup from './popups/PausedPopup';

const App = () => {
  const [imageVisible, setImageVisible] = useState(false);
  const [overlayOpacity, setOverlayOpacity] = useState(0);
  const [isFAQVisible, setFAQVisible] = useState(false);
  const [faqShowHoundsLink, setFAQShowHoundsLink] = useState(false);

  const audioRef = useRef(new Audio(introAudio));
  const [muteAudio, setMuteAudio] = useState(false);
  const [currentScreen, setCurrentScreen] = useState('intro');
  const currentScreenRef = useRef(currentScreen);
  const [screenStates, setScreenStates] = useState(null);
  const [navbarOpacity, setNavbarOpacity] = useState(1);

const handleIntroEnd = async () => {
    await transitionHome();
  };

  const transitionHome = async (transitionType) => {
      if (!transitionType) transition('home', 'none', 'screen-fade-out', 'screen-fade-in');
      else transition('home', transitionType);
  };

  const handleShowFAQ = (showHoundsLink) => {
    setFAQShowHoundsLink(showHoundsLink);
    setFAQVisible(true);
  };

  /*NAVBAR TRANSITIONS*/

  const handleIdentitySourceClick = () => {
    transitionHome('right');
  };

  const handleLogoClick = () => {
    const transitionType = currentScreen === 'trinity' || currentScreen === 'genealogy' ? 'left' : 'right';
    if (currentScreen !== 'home') {
      transitionHome(transitionType);
    }
  };

  const handleHomeAction = () => {
    console.log('Home action');
  }

  const handleNavbarAction = () => {
    console.log('Navbar action');
  }

  useEffect(() => {
    // Set up initial state with props for all screens
    setScreenStates({
      intro: {
        visibility: 'visible',
        animationClass: '',
        component: IntroScreen,
        props: { onIntroEnd: handleIntroEnd },
      },
      home: {
        visibility: 'hidden',
        animationClass: '',
        component: HomeScreen,
        props: {
          onAction: handleHomeAction,
          onTrinity: () => transition('trinity', 'right'),
          onGenealogy: () => transition('genealogy', 'right'),
          onShowFAQ: handleShowFAQ,
        },
      },
      trinity: {
        visibility: 'hidden',
        animationClass: '',
        component: TrinityScreen,
        props: {
          onShowFAQ: handleShowFAQ,
          onHome: () => {
            transitionHome('left');
          },
          onGenealogy: () => transition('genealogy', 'left'),
        },
      },
      genealogy: {
        visibility: 'hidden',
        animationClass: '',
        component: GenealogyScreen,
        props: {
          onShowFAQ: handleShowFAQ,
          onHome: () => {
            transitionHome('left');
          },
          onTrinity: () => transition('trinity', 'right'),
        },
      },
      scene: {
        visibility: 'hidden',
        animationClass: '',
        images: [roomImage, roomImage1],
      },
    });
    setCurrentScreen('intro');
  }, []);

  useEffect(() => {
    const inBalanceScreen = currentScreen === 'balance';

    if (currentScreen === 'intro' || (inBalanceScreen && currentScreen !== 'balance')) {
      playAudio(introAudio, 1000);
    } else if (currentScreen === 'balance') {
      playAudio(validateAudio, 1000);
    }

    currentScreenRef.current = currentScreen;
    // setImageVisible(currentScreen === 'transition-connect' || currentScreen === 'transition-end');
  }, [currentScreen]);

  const playAudio = (audioClip, transitionTime) => {
    const audio = audioRef.current;
    stopAudio();
    setTimeout(() => {
      audio.src = audioClip;
      audio.loop = true;
      audio.muted = muteAudio;
      audio.play().catch((error) => {
        console.error('Audio playback error:', error);
      });
    }, transitionTime);
  };

  const stopAudio = () => {
    const audio = audioRef.current;
    audio.pause();
    audio.currentTime = 0;
  };

  const toggleAudio = () => {
    const audio = audioRef.current;
    console.log('Toggling audio');
    setMuteAudio((prevState) => {
      const newState = !prevState;
      audio.muted = newState;
      return newState;
    });
  };

  const setState = (screenName, visibility, animationClass = '') => {
    setScreenStates((prev) => ({
      ...prev,
      [screenName]: {
        ...prev[screenName],
        visibility,
        animationClass,
      },
    }));
  };

  const getOutAnimation = (transitionType) => {
    return transitionType === 'left' ? 'slide-out-left' : 'slide-out-right';
  };

  const getInAnimation = (transitionType) => {
    return transitionType === 'left' ? 'slide-in-right' : 'slide-in-left';
  };

  const getSceneAnimation = (transitionType) => {
    return transitionType === 'left' ? 'transition-right' : 'transition-left';
  };

  const transition = (toScreen, transitionType, outAnimation, inAnimation) => {
    const fromScreen = currentScreenRef.current;

    let sceneAnimation = '';
    if (!transitionType || transitionType === 'none') {
      setOverlayOpacity(0);
      setImageVisible(false);
      setNavbarOpacity(1); // Update Navbar opacity when no transition
    } else {
      if (!outAnimation) outAnimation = getOutAnimation(transitionType);
      if (!inAnimation) inAnimation = getInAnimation(transitionType);
      sceneAnimation = getSceneAnimation(transitionType);

      setImageVisible(true);
      setOverlayOpacity(0.9);
      setNavbarOpacity(0); // Fade out Navbar during animation
    }

    setState(fromScreen, 'transition', outAnimation);
    setState(toScreen, 'transition', inAnimation);
    setState('scene', 'transition', sceneAnimation);

    const waitTime = transitionType === 'none' ? 0 : 2000;

    setTimeout(() => {
      setState(fromScreen, 'hidden');
      setCurrentScreen(toScreen);
      setState(toScreen, 'visible');
      setState('scene', 'hidden');
      setImageVisible(false);
      setOverlayOpacity(0);
      setNavbarOpacity(1); // Restore Navbar opacity after transition
    }, waitTime);
  };

  const renderScreens = () => {
    if (!screenStates) return null;
    return Object.entries(screenStates)
      .map(([key, { visibility, animationClass, component: Component, props }]) => {
        if (visibility !== 'hidden' && Component) {
          return <Component key={key} animationClass={animationClass} {...props} />;
        }
        return null;
      })
      .filter(Boolean);
  };

  const renderSceneImages = () => {
    if (!screenStates) return null;
    const { visibility, animationClass, images } = screenStates.scene;
    if (imageVisible && images) {
      return images.map((imageSrc, index) => (
        <img
          key={index}
          src={imageSrc}
          alt={`Transition ${index + 1}`}
          className={`transition-image ${visibility !== 'hidden' ? `visible ${animationClass}` : 'hidden'}`}
        />
      ));
    }
    return null;
  };

  return (
    <ConnectionStatusProvider>
      <div className="content-frame">
        <div className="screen-background"></div>
        <NavbarComponent
          onIdentitySourceClick={handleIdentitySourceClick}
          onRegisterClick={handleNavbarAction}
          onShowFAQ={handleShowFAQ}
          onLogoClick={handleLogoClick}
          setNavbarOpacity={setNavbarOpacity}
          navbarOpacity={navbarOpacity}
        />
        <div className="audio-wrapper">
          <button className={`audio-button ${muteAudio ? 'stopped' : 'playing'}`} onClick={toggleAudio}></button>
        </div>
        <div className="overlay" style={{ opacity: overlayOpacity }}></div>
        <div className="tilt">
        <div className="bg-video">
          <video autoPlay loop muted playsInline>
            <source src={VideoHome} type="video/webm" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="mobile-content"><img src={LogoImage} alt="Logo" className="logo" />
        Due to cross-chain functionality this mint and the wallets used are<b>only supported on desktop</b></div></div>

        {renderSceneImages()}
        {renderScreens()}

        {<FAQpopup showFAQ={isFAQVisible} showHoundsLink={faqShowHoundsLink} onClose={() => setFAQVisible(false)} />}
      </div>
    </ConnectionStatusProvider>
  );
};

export default App;
