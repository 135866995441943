import React, { useState } from 'react';
import '../css/normalize.css';
import '../css/webflow.css';
import '../css/ordinals.css';
import FooterComponent from '../components/FooterComponent';
import tcenterImage from '../images/tcenter.png';
import trightImage from '../images/tright.png';
import tleftImage from '../images/tleft.png';
import t2leftImage from '../images/t2left.png';
import t2centerImage from '../images/t2center.png';
import tdarkImage from '../images/tdark.png';

const TrinityScreen = ({ animationClass, onHome, onShowFAQ, onGenealogy }) => {
  const [selectedBox, setSelectedBox] = useState(null);
  const [isAdditionalContentVisible, setAdditionalContentVisible] = useState(false);

  const handleBoxClick = (boxNumber) => {
    setSelectedBox(boxNumber);
    setTimeout(() => {
      setAdditionalContentVisible(true); // Show additional content after a short delay
    }, 10); // Adjust delay as needed
  };

  const handleAdditionalContentClick = () => {
    const additionalContent = document.querySelector('.additional-content');
    additionalContent.classList.add('fade-out'); // Add the 'fade-out' class to trigger the fade-out transition
    setTimeout(() => {
      setSelectedBox(null);
      setAdditionalContentVisible(false);
      additionalContent.classList.remove('fade-out'); // Remove the 'fade-out' class after the transition completes
    }, 500); // Wait for the transition to complete before setting additionalContentVisible to false
  };

  const handleFooterAction = () => {
    onHome();
  };

  const handleGenealogyClick = () => {
    if (onGenealogy) onGenealogy();
  };

  return (
    <div className={`content-frame ${animationClass}`}>
      <div className="trinity-section">
        <div className="t-wrapper">
          <div
            className={`t-box ${selectedBox === 1 ? 'selected' : ''}`}
            onClick={() => handleBoxClick(1)}
            style={{ opacity: selectedBox === 1 ? '1' : '1' }} // Start all boxes with 100% opacity
          >
            <img src={tleftImage} alt="Room" />
          </div>
          <div
            className={`t-box ${selectedBox === 2 ? 'selected' : ''}`}
            onClick={() => handleBoxClick(2)}
            style={{ opacity: selectedBox === 2 ? '1' : '1' }} // Set opacity to 15% for unselected boxes
          >
            <img src={tcenterImage} alt="Room" />
          </div>
          <div
            className={`t-box ${selectedBox === 3 ? 'selected' : ''}`}
            onClick={() => handleBoxClick(3)}
            style={{ opacity: selectedBox === 3 ? '1' : '1' }} // Set opacity to 15% for unselected boxes
          >
            <img src={trightImage} alt="Room" />
          </div>
        </div>
        {selectedBox && (
          <div
            className={`additional-content ${isAdditionalContentVisible ? 'visible' : ''}`}
            onClick={handleAdditionalContentClick}
          >
            {selectedBox === 1 && (
              <>
                <div className="trinity-wrapper">
                  <div className="t-wrapper">
                    <div className="t-box">
                      <img src={tleftImage} alt="Room" />
                    </div>
                    <div className="t-box">
                      <img src={t2centerImage} alt="Room" />
                    </div>
                    <div className="t-box">
                      <img src={tdarkImage} alt="Room" />
                    </div>
                  </div>
                </div>
                <div className="tbox-left"></div>
                <div className="t-box-content-1">
                  <h2 className="h2">Arbiters Of The Source</h2>
                  <p className="paragraph red3">THE FIRST </p>
                  <p className="paragraph red2">
                    Alpha Uncommon<br></br>
                    <b>119080000000000</b>
                  </p>
                  <p className="paragraph red2">First sat of a Block +Bitcoin</p>
                  <p className="paragraph red3">THE INFINITE</p>
                  <p className="paragraph red2">
                    Perfect Palinception<br></br>
                    <b>28969822896982</b>
                  </p>
                  <p className="paragraph red2">Repeating and reflected ID number</p>
                  <p className="paragraph red3">
                    THE LAST<br></br>
                  </p>
                  <p className="paragraph red2">
                    Omega Black Uncommon<br></br>
                    <b>53874999999999</b>
                    <br></br>
                  </p>
                  <p className="paragraph red2">Last sat of a block + Bitcoin</p>
                </div>
              </>
            )}
            {selectedBox === 2 && (
              <>
                <div className="trinity-wrapper">
                  <div className="t-wrapper">
                    <div className="t-box">
                      <img src={t2leftImage} alt="Room" />
                    </div>
                    <div className="t-box">
                      <img src={t2centerImage} alt="Room" />
                    </div>
                    <div className="t-box">
                      <img src={t2centerImage} alt="Room" />
                    </div>
                  </div>
                </div>
                <div className="tbox-mid"></div>
                <div className="t-box-content-2">
                  <h2 className="h2">Arbiters Of The Source</h2>
                  <p className="paragraph red3">THE FIRST </p>
                  <p className="paragraph red2">
                    Alpha Uncommon<br></br>
                    <b>119080000000000</b>
                  </p>
                  <p className="paragraph red2">First sat of a Block +Bitcoin</p>
                  <p className="paragraph red3">THE INFINITE</p>
                  <p className="paragraph red2">
                    Perfect Palinception<br></br>
                    <b>28969822896982</b>
                  </p>
                  <p className="paragraph red2">Repeating and reflected ID number</p>
                  <p className="paragraph red3">
                    THE LAST<br></br>
                  </p>
                  <p className="paragraph red2">
                    Omega Black Uncommon<br></br>
                    <b>53874999999999</b>
                    <br></br>
                  </p>
                  <p className="paragraph red2">Last sat of a block + Bitcoin</p>
                </div>
              </>
            )}
            {selectedBox === 3 && (
              <>
                <div className="trinity-wrapper">
                  <div className="t-wrapper">
                    <div className="t-box">
                      <img src={tdarkImage} alt="Room" />
                    </div>
                    <div className="t-box">
                      <img src={t2leftImage} alt="Room" />
                    </div>
                    <div className="t-box">
                      <img src={trightImage} alt="Room" />
                    </div>
                  </div>
                </div>
                <div className="tbox-right"></div>
                <div className="t-box-content-3">
                  <h2 className="h2">Arbiters Of The Source</h2>
                  <p className="paragraph red3">THE FIRST </p>
                  <p className="paragraph red2">
                    Alpha Uncommon<br></br>
                    <b>119080000000000</b>
                  </p>
                  <p className="paragraph red2">First sat of a Block +Bitcoin</p>
                  <p className="paragraph red3">THE INFINITE</p>
                  <p className="paragraph red2">
                    Perfect Palinception<br></br>
                    <b>28969822896982</b>
                  </p>
                  <p className="paragraph red2">Repeating and reflected ID number</p>
                  <p className="paragraph red3">
                    THE LAST<br></br>
                  </p>
                  <p className="paragraph red2">
                    Omega Black Uncommon<br></br>
                    <b>53874999999999</b>
                    <br></br>
                  </p>
                  <p className="paragraph red2">Last sat of a block + Bitcoin</p>
                </div>
              </>
            )}
          </div>
        )}
      </div>
      <FooterComponent
        activeButton="trinity"
        onIdentitySourceClick={() => console.log('Identity Source Clicked')}
        onHomeClick={handleFooterAction}
        onGenealogyClick={handleGenealogyClick}
        onShowFAQ={onShowFAQ}
      />
    </div>
  );
};

export default TrinityScreen;
