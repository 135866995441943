import React, { useState, useRef, useEffect } from 'react';
import '../css/normalize.css';
import '../css/webflow.css';
import '../css/ordinals.css';
import FooterComponent from '../components/FooterComponent';

const GenealogyScreen = ({ animationClass, onHome, onShowFAQ, onTrinity }) => {
  const handleFooterAction = () => {
    onHome();
  };

  const handleTrinityAction = () => {
    if (onTrinity) onTrinity();
  };

  return (
    <div className={`content-frame ${animationClass}`}>
      <div className="Genealogy-section"></div>
      <FooterComponent
        activeButton="Genealogy"
        onIdentitySourceClick={() => console.log('Identity Source Clicked')}
        onRegisterClick={handleFooterAction}
        onTrinityClick={handleTrinityAction}
        onShowFAQ={onShowFAQ}
        onHomeClick={handleFooterAction}
      />
    </div>
  );
};

export default GenealogyScreen;
