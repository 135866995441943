import React from 'react';

const FooterComponent = ({
  activeButton,
  onHomeClick,
  onIdentitySourceClick,
  onGenealogyClick,
  onShowFAQ,
  onTrinityClick,
}) => {
  const handleHomeClick = () => {
    if (onHomeClick) onHomeClick();
  };

  const handleIdentitySourceClick = () => {
    if (onIdentitySourceClick) onIdentitySourceClick();
  };

  const handleGenealogyClick = () => {
    if (onGenealogyClick) onGenealogyClick();
  };

  const handleTrinityClick = () => {
    if (onTrinityClick) onTrinityClick();
  };

  const handleShowFAQ = () => {
    if (onShowFAQ) onShowFAQ(false);
  };

  return (
    <div className="bot-bar">
      <div className="container-regular">
        <div className="navbar-wrapper">
          <nav className="bot-bar-wrapper w-nav-menu">
            <ul className="bot-menu w-list-unstyled">
              <li className="mobile-margin-top-10">
                <div className="bot-btn-wrapper" onClick={handleHomeClick}>
                  <div className={`btn-1 ${activeButton === 'home' ? 'on' : 'off'}`}>HOME</div>
                </div>
              </li>
              <li className="mobile-margin-top-10">
                <div className="bot-btn-wrapper" onClick={handleTrinityClick}>
                  <div className={`btn-1 ${activeButton === 'trinity' ? 'on' : 'off'}`}>TRINITY</div>
                </div>
              </li>
              <li className="mobile-margin-top-10">
                <div className="bot-btn-wrapper" onClick={handleGenealogyClick}>
                  <div className={`btn-1 ${activeButton === 'Genealogy' ? 'on' : 'off'}`}>GENEALOGY</div>
                </div>
              </li>
              {/* <li className="mobile-margin-top-10">
                <div className="bot-btn-wrapper" onClick={onRegisterClick}>
                  <div className={`btn-1 ${activeButton === 'register' ? 'on' : 'off'}`}>REGISTER</div>
                </div>
              </li> */}
              <li className="mobile-margin-top-10">
                <div className="bot-btn-wrapper" onClick={handleShowFAQ}>
                  <div className={`btn-1 ${activeButton === 'faq' ? 'on' : 'off'}`}>FAQ</div>
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default FooterComponent;
