import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../images/Logo.png';
import BurgerBar from '../images/BurgerBar.svg';

const NavbarComponent = ({
  navbarOpacity,
  onIdentitySourceClick,
  onRegisterClick,
  onShowFAQ,
  onLogoClick,
  activeButton,
}) => {
  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);

  const toggleBurgerMenu = () => {
    setBurgerMenuOpen(!burgerMenuOpen);
  };

  return (
    <div className="nav-wrapper" style={{ opacity: navbarOpacity, transition: 'opacity 1s' }}>
      <Link to="/" className="navbar-brand w-nav-brand" onClick={onLogoClick}>
        <img src={Logo} alt="Logo" loading="lazy" />
      </Link>
      <div className="burger-box">
        <img className="burger" src={BurgerBar} alt="Another Image" loading="lazy" onClick={toggleBurgerMenu} />
        {burgerMenuOpen && (
          <div className="burger-menu">
            {/* <div className="bot-btn-wrapper" onClick={onIdentitySourceClick}>
              <div className={`btn-burger ${activeButton === 'identitySource' ? 'on' : 'off'}`}>IDENTITY SOURCE</div>
            </div> */}
            {/* <div className="bot-btn-wrapper" onClick={onRegisterClick}>
              <div className={`btn-burger ${activeButton === 'register' ? 'on' : 'off'}`}>VERIFICATION</div>
            </div> */}
            <div className="bot-btn-wrapper" onClick={onShowFAQ}>
              <div className={`btn-burger ${activeButton === 'faq' ? 'on' : 'off'}`}>FAQ</div>
            </div>
            <div className="bot-btn-wrapper btn-burger">
  <a className="nav-a" href="http://www.novellabs.xyz/mh-inscriptions-terms" target="_blank">TERMS & CONDITIONS</a>
</div>
          </div>
        )}
      </div>
    </div>
  );
};
export default NavbarComponent;
