import React, { useState, useEffect } from 'react';
import { useWallets } from '@wallet-standard/react';
import BitcoinService from '../services/BitcoinService';
import walletImage from '../images/xverse.png';
import walletImage2 from '../images/Unisat.png';
import walletImage3 from '../images/ME.png';
import walletImage4 from '../images/leather_icon.png';

const SatsConnectNamespace = 'sats-connect:';

function isSatsConnectCompatibleWallet(wallet) {
  return SatsConnectNamespace in wallet.features;
}

const BTCWalletManagerComponent = ({ onWalletConnected, onWalletDisconnected, onClose }) => {
  const { wallets } = useWallets();
  const [unisatInstalled, setUnisatInstalled] = useState(false);
  const [magicEdenWallet, setMagicEdenWallet] = useState(null);
  const [magicEdenInstalled, setMagicEdenInstalled] = useState(false);
  const [addresses, setAddresses] = useState([]);

  const walletInstallUrls = {
    xverse: 'https://www.xverse.app/download',
    unisat: 'https://unisat.io',
    magicEden: 'https://wallet.magiceden.io/',
    leather: 'https://leather.io/install-extension',
  };

  // Filter and update available Sats wallets
  useEffect(() => {
    const satsWallets = wallets.filter(isSatsConnectCompatibleWallet);
    const wallet = satsWallets.find((wallet) => wallet.name === 'Magic Eden');

    if (wallet) {
      setMagicEdenInstalled(true);
      setMagicEdenWallet(wallet);
    } else {
      setMagicEdenInstalled(false);
      setMagicEdenWallet(null);
    }
  }, [wallets]);

  useEffect(() => {
    const checkUnisatWallet = async () => {
      let unisat = window.unisat;

      for (let i = 1; i < 10 && !unisat; i += 1) {
        await new Promise((resolve) => setTimeout(resolve, 100 * i));
        unisat = window.unisat;
      }

      if (unisat) {
        setUnisatInstalled(true);
      } else if (!unisat) {
        setUnisatInstalled(false);
        return;
      }

      unisat.getAccounts().then((accounts) => {
        handleAccountsChanged(accounts);
      });

      unisat.on('accountsChanged', handleAccountsChanged);
      unisat.on('networkChanged', handleNetworkChanged);

      return () => {
        unisat.removeListener('accountsChanged', handleAccountsChanged);
        unisat.removeListener('networkChanged', handleNetworkChanged);
      };
    };

    checkUnisatWallet();
  }, []);

  const handleAccountsChanged = (accounts) => {
    // if (accounts && accounts.length > 0) {
    //     setUnisatConnected(true);
    //     setUnisatAddress(accounts[0]);
    // } else {
    //     setUnisatConnected(false);
    //     setUnisatAddress('');
    // }
  };

  const handleNetworkChanged = (networkId) => {
    console.log('Network changed:', networkId);
  };

  const handleWalletConnected = async (walletAddresses, walletType) => {
    if (onWalletConnected) onWalletConnected(walletAddresses, walletType);
    setAddresses(walletAddresses);
  };

  const handleWalletDisconnected = () => {
    if (onWalletDisconnected) onWalletDisconnected();
    setAddresses([]);
  };

  const handleConnect = async (walletName) => {
    console.log('Connecting wallet:', walletName);
    try {
      let walletsToConnect = [];

      if (walletName === 'xverse') {
        const results = await BitcoinService.connectXverseWallet();
        // Assuming the service returns an array of wallet objects
        walletsToConnect = results.map((wallet) => ({
          address: wallet.address,
          publicKey: wallet.publicKey,
          type: wallet.purpose, // 'ordinal', 'payment', etc.
        }));
      } else if (walletName === 'magicEden' && magicEdenInstalled && magicEdenWallet) {
        const results = await BitcoinService.connectMagicEdenWallet(magicEdenWallet);
        walletsToConnect = results.map((wallet) => ({
          address: wallet.address,
          publicKey: wallet.publicKey,
          type: wallet.purpose,
        }));
      } else if (walletName === 'unisat' && unisatInstalled) {
        const unisat = window.unisat;
        const results = await BitcoinService.connectUnisatWallet(unisat);

        let walletsToConnectPromises = results.map(async (wallet) => {
          const publicKey = await unisat.getPublicKey();
          // Return an array with two entries
          return [
            {
              address: wallet,
              publicKey: publicKey,
              type: 'ordinals',
            },
            {
              address: wallet,
              publicKey: publicKey,
              type: 'payment',
            },
          ];
        });

        walletsToConnect = (await Promise.all(walletsToConnectPromises)).flat();
      } else if (walletName === 'leather' && window.btc) {
        const results = await BitcoinService.connectLeatherWallet();
        walletsToConnect = results
          .filter((wallet) => wallet.symbol === 'BTC') // Filter only BTC symbol wallets
          .map((wallet) => ({
            address: wallet.address,
            publicKey: wallet.publicKey,
            // Assign 'ordinals' if the type is 'p2tr', otherwise 'payment'
            type: wallet.type === 'p2tr' ? 'ordinals' : 'payment',
          }));
      } else {
        // If wallet not installed, direct user to install URL
        const installUrl = walletInstallUrls[walletName];
        if (installUrl) {
          console.log(`Redirecting to ${walletName} wallet install page.`);
          window.open(installUrl, '_blank', 'noreferrer');
        }
        return; // Exit function if wallet is not supported or not installed
      }

      if (walletsToConnect.length > 0) {
        handleWalletConnected(walletsToConnect, walletName);
      } else {
        throw new Error('No Bitcoin wallets found.');
      }
    } catch (error) {
      console.error('Failed to connect wallet:', error);
      handleWalletDisconnected(error);
    }
  };

  const handleClose = () => {
    if (onClose) onClose();
  };

  return (
    <div className="btc-wallet-wrapper">
      <div className="wallet-box">
        <div className="wallet-box-wrapper">
          <h2 className="wallet-heading">Connect your BTC wallet</h2>
          <button className="btn-wallet" onClick={() => handleConnect('magicEden')}>
            <img src={walletImage3} alt="wallet-icon"></img>Magic Eden Wallet <div className="pref">Recommended</div>
          </button>
          <button className="btn-wallet" onClick={() => handleConnect('xverse')}>
            <img src={walletImage} alt="wallet-icon"></img>XVerse wallet
          </button>
          <button className="btn-wallet" onClick={() => handleConnect('unisat')}>
            <img src={walletImage2} alt="wallet-icon"></img>Unisat Wallet
          </button>
          <button className="btn-wallet" onClick={() => handleConnect('leather')}>
            <img src={walletImage4} alt="wallet-icon"></img>Leather Wallet
          </button>
          <button className="btn_connect" onClick={handleClose}>
            CLOSE
          </button>
        </div>
      </div>
    </div>
  );
};

export default BTCWalletManagerComponent;
