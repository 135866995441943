import {
  getAddress,
  sendBtcTransaction,
  AddressPurpose,
  BitcoinNetworkType,
  signTransaction,
  signMessage as satsConnectSignMessage,
} from 'sats-connect';
import { Psbt } from 'bitcoinjs-lib';
import { fetchUTXO } from '../utils/fetchUTXO';
import { selectUTXOs } from '../utils/selectUTXO';
import { createPSBT } from '../utils/createPSBT';
import axios from 'axios';

async function getTransferPayload(amount, senderAddress, recipient, networkType) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_WEB_SERVER}/api/create-transfer-payload`,
      {
        amount,
        senderAddress,
        recipient,
        networkType,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.REACT_APP_API_KEY,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error fetching transfer payload:', error);
    throw error;
  }
}

const BitcoinService = {
  currentWallet: null,
  magicEdenWallet: null,
  useMainnet: process.env.REACT_APP_BTC_USE_MAINNET === 'true' ? true : false,
  // connect xverse wallet
  connectXverseWallet: () => {
    const networkType = BitcoinService.useMainnet ? BitcoinNetworkType.Mainnet : BitcoinNetworkType.Testnet;
    return new Promise((resolve, reject) => {
      const getAddressOptions = {
        payload: {
          purposes: ['ordinals', 'payment'],
          message: 'Address for receiving Ordinals and payments',
          network: {
            type: networkType,
          },
        },
        onFinish: (response) => {
          console.log(response);
          // Resolve the promise with the first address
          if (response && response.addresses && response.addresses.length > 0) {
            BitcoinService.currentWallet = 'xverse';
            resolve(response.addresses);
          } else {
            reject(new Error('No addresses found'));
          }
        },
        onCancel: () => {
          reject(new Error('Request canceled by user'));
        },
      };

      // Call the getAddress function with the options
      getAddress(getAddressOptions).catch((err) => {
        console.error('Error connecting to Xverse Wallet:', err);
        reject(new Error('No Bitcoin wallet installed or another error occurred'));
      });
    });
  },
  connectUnisatWallet: async (unisat) => {
    return new Promise(async (resolve, reject) => {
      try {
        console.log('Connecting Unisat Wallet');
        let currentNet = await window.unisat.getNetwork();
        if (currentNet !== (BitcoinService.useMainnet ? 'mainnet' : 'testnet')) {
          const newNetwork = BitcoinService.useMainnet ? 'mainnet' : 'testnet';
          console.log('Switching network to', newNetwork);
          await unisat.switchNetwork(newNetwork);
        }

        const response = await unisat.requestAccounts();
        if (response && response.length > 0) {
          BitcoinService.currentWallet = 'unisat';
          resolve(response);
        }
        reject(new Error('No addresses found'));
      } catch (err) {
        console.error('Failed to connect Magic Eden Wallet:', err);
        reject(err);
      }
    });
  },
  // connect magic eden wallet
  connectMagicEdenWallet: (wallet) => {
    const networkType = BitcoinService.useMainnet ? BitcoinNetworkType.Mainnet : BitcoinNetworkType.Testnet;

    return new Promise(async (resolve, reject) => {
      BitcoinService.magicEdenWallet = wallet;
      if (!wallet) {
        reject(new Error('Wallet is not provided'));
        return;
      }

      const walletType = wallet.type;
      BitcoinService.currentWallet = walletType;
      try {
        console.log('Connecting', walletType, networkType);
        await getAddress({
          getProvider: async () => wallet.features['sats-connect:']?.provider,
          payload: {
            purposes: [AddressPurpose.Ordinals, AddressPurpose.Payment],
            message: 'Address for receiving Ordinals and payments',
            network: {
              type: networkType,
            },
          },
          onFinish: (response) => {
            // Resolve the promise with the first address if available
            if (response && response.addresses && response.addresses.length > 0) {
              console.log(response);
              BitcoinService.currentWallet = 'magicEden';
              resolve(response.addresses);
            } else {
              console.error('No addresses found');
              reject(new Error('No addresses found'));
            }
          },
          onCancel: () => {
            console.log('Request canceled by user');
            reject(new Error('Request canceled by user'));
          },
        });
      } catch (err) {
        console.error('No Bitcoin wallet installed', err);
        reject(err);
      }
    });
  },
  // connect leather wallet
  connectLeatherWallet: async () => {
    return new Promise(async (resolve, reject) => {
      // Check if the Leather Wallet is available in the window object
      if (window.btc && typeof window.btc.request === 'function') {
        try {
          // Request BTC and STX addresses from the user's Leather Wallet
          const userAddresses = await window.LeatherProvider?.request('getAddresses');
          if (userAddresses && userAddresses.result && userAddresses.result.addresses) {
            // Successfully retrieved addresses
            console.log('Leather Wallet addresses:', userAddresses.result.addresses);

            BitcoinService.currentWallet = 'leather';

            resolve(userAddresses.result.addresses);
          } else {
            // The response structure is not as expected
            reject(new Error('Failed to retrieve addresses from Leather Wallet.'));
          }
        } catch (err) {
          console.error('Error connecting to Leather Wallet:', err);
          reject(new Error('Error connecting to Leather Wallet.'));
        }
      } else {
        // Leather Wallet not available or not properly injected
        reject(new Error('No Bitcoin wallet installed'));
      }
    });
  },
  disconnectWallet: async () => {
    switch (BitcoinService.currentWallet) {
      case 'xverse':
        console.log('Disconnecting Xverse wallet');
        break;
      case 'unisat':
        console.log('Disconnecting Unisat wallet');
        break;
      case 'magicEden':
        console.log('Disconnecting Magic Eden wallet');
        break;
      default:
        console.log('No wallet to disconnect');
    }
    // Reset currentWallet to null after disconnecting
    BitcoinService.currentWallet = null;
  },
  signMessage: ({ address, message, walletType }) => {
    console.log(`Signing message with ${walletType} wallet`);
    const networkType = BitcoinService.useMainnet ? BitcoinNetworkType.Mainnet : BitcoinNetworkType.Testnet;
    return new Promise(async (resolve, reject) => {
      console.log(`Signing message with ${walletType} wallet`);
      try {
        switch (walletType) {
          case 'xverse':
            console.log('Signing message with Xverse wallet', address, message);
            // Updated to use the example provided
            const xverseSignMessageOptions = {
              payload: {
                address: address,
                message: message,
                network: {
                  type: networkType,
                },
              },
              onFinish: (response) => {
                // Handle the successful signing here
                console.log('Message signed:', response);
                resolve(response); // Resolve with the response from the wallet
              },
              onCancel: () => {
                // Handle the cancellation here
                console.log('Signing message cancelled');
                reject(new Error('Signing message cancelled by user'));
              },
            };
            // Call the signMessage function from the wallet with the options
            await satsConnectSignMessage(xverseSignMessageOptions);
            break;
          case 'unisat':
            const unisat = window.unisat; // Assuming Unisat exposes a global object
            if (!unisat) {
              throw new Error('Unisat wallet is not available');
            }
            const unisatSignResult = await unisat.signMessage(message);
            resolve(unisatSignResult);
            break;
          case 'magicEden':
            const magicEdenSignMessageOptions = {
              payload: {
                network: {
                  type: networkType,
                },
                address: address,
                message: message,
              },
              onFinish: (response) => {
                console.log('Message signed by Magic Eden:', response);
                resolve(response);
              },
              onCancel: () => {
                console.log('Signing message with Magic Eden cancelled');
                reject(new Error('Signing message cancelled by user'));
              },
              getProvider: async () => {
                return BitcoinService.magicEdenWallet.features['sats-connect:']?.provider;
              },
            };
            await satsConnectSignMessage(magicEdenSignMessageOptions);
            break;
          case 'leather':
            console.log('Signing message with Leather wallet', address, message);
            // Check if Leather Wallet is available
            if (window.btc && typeof window.btc.request === 'function') {
              const response = await window.btc.request('signMessage', {
                message: message,
                paymentType: 'p2tr', // or 'p2wpkh' based on the address type
              });
              if (response && response.result && response.result.signature) {
                console.log('Message signed by Leather wallet:', response.result.signature);
                resolve(response.result.signature);
              } else {
                reject(new Error('Failed to sign message with Leather Wallet.'));
              }
            } else {
              reject(new Error('Leather Wallet is not available.'));
            }
            break;
          default:
            reject(new Error('Unsupported wallet type'));
        }
      } catch (error) {
        console.error(`Error signing message with ${walletType} wallet:`, error);
        reject(error);
      }
    });
  },
  getBtcBalance: async (address) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_WEB_SERVER}/getBtcBalance`, {
        params: { address },
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
        },
      });
      return response.data.balance;
    } catch (error) {
      console.error('Error fetching Bitcoin balance:', error);
      throw error;
    }
  },
  makePayment: async (amount, senderAddress) => {
    const bigIntAmount = BigInt(amount);
    const networkType = BitcoinService.useMainnet ? BitcoinNetworkType.Mainnet : BitcoinNetworkType.Testnet;
    const recipient = BitcoinService.useMainnet
      ? process.env.REACT_APP_MAINNET_RECEIVE_WALLET
      : process.env.REACT_APP_TESTNET_RECEIVE_WALLET;
    switch (BitcoinService.currentWallet) {
      case 'xverse':
        console.log('makePayment Xverse wallet', networkType, bigIntAmount);

        if (!recipient) {
          console.error('Recipient address is not defined.');
          return Promise.reject('Recipient address is not defined.');
        }

        const sendBtcOptions = {
          payload: {
            network: { type: networkType },
            recipients: [{ address: recipient, amountSats: bigIntAmount }],
            senderAddress: senderAddress,
          },
          onFinish: null, // Will be set below
          onCancel: () => {
            console.error('Transaction canceled by user');
            return Promise.reject('Transaction canceled by user');
          },
        };

        // Create a promise that resolves or rejects based on the onFinish and onCancel callbacks
        return new Promise((resolve, reject) => {
          sendBtcOptions.onFinish = (response) => {
            console.log('Transaction sent:', response);
            resolve(response); // Resolve the promise with the response
          };
          sendBtcOptions.onCancel = () => {
            console.error('Transaction canceled by user');
            reject('Transaction canceled by user');
          };

          sendBtcTransaction(sendBtcOptions).catch((err) => {
            console.error('Error sending BTC transaction:', err);
            reject(err); // Reject the promise with the error
          });
        });
      case 'unisat':
        try {
          console.log('makePayment Unisat wallet');
          // Ensure the window.unisat is available and useMainnet flag is correctly handled
          const recipient = BitcoinService.useMainnet
            ? process.env.REACT_APP_MAINNET_RECEIVE_WALLET
            : process.env.REACT_APP_TESTNET_RECEIVE_WALLET;
          if (!recipient) {
            console.error('Recipient address is not defined.');
            return Promise.reject('Recipient address is not defined.');
          }

          // Send Bitcoin using Unisat's sendBitcoin method
          const txid = await window.unisat.sendBitcoin(recipient, amount);
          console.log('Transaction ID:', txid);
          return Promise.resolve(txid);
        } catch (error) {
          console.error('Error with Unisat payment:', error);
          return Promise.reject(error);
        }
      case 'magicEden':
        console.log(
          'makePayment Magic Eden wallet sender address',
          senderAddress,
          'recipient address',
          recipient,
          'amount',
          amount
        );

        return new Promise(async (resolve, reject) => {
          // Wrap in a new Promise
          try {
            const networkType = BitcoinService.useMainnet ? BitcoinNetworkType.Mainnet : BitcoinNetworkType.Testnet;

            // Replace the direct payload generation with a call to getTransferPayload
            const payload = await getTransferPayload(amount, senderAddress, recipient, networkType);
            console.log('Transfer payload:', payload);

            await signTransaction({
              payload: payload,
              onFinish: async (response) => {
                console.log('Transaction signed:', response);
                const psbResponse = Psbt.fromBase64(response.psbtBase64);
                psbResponse.finalizeAllInputs();
                const signedTx = psbResponse.extractTransaction();
                const txHex = signedTx.toHex();

                try {
                  let url = 'https://mempool.space/api/tx';
                  if (!BitcoinService.useMainnet) {
                    url = 'https://mempool.space/testnet/api/tx';
                  }
                  const broadcastResponse = await fetch(url, {
                    method: 'POST',
                    body: txHex,
                    headers: {
                      'Content-Type': 'text/plain',
                    },
                  });

                  if (!broadcastResponse.ok) {
                    throw new Error('Failed to broadcast transaction');
                  }

                  const txid = await broadcastResponse.text(); // The txid will be returned as plain text
                  console.log(`Transaction broadcasted successfully. TXID: ${txid}`);
                  resolve(txid); // Resolve the Promise with the txid
                } catch (error) {
                  console.error('Error broadcasting transaction:', error);
                  reject(error); // Reject the Promise on error
                }
              },
              onCancel: () => {
                reject('Transaction canceled by user');
              },
              getProvider: async () => {
                return BitcoinService.magicEdenWallet.features['sats-connect:']?.provider;
              },
            });
          } catch (error) {
            console.error('Error with Magic Eden payment:', error);
            reject(error);
          }
        });
      case 'leather':
        return new Promise(async (resolve, reject) => {
          try {
            // Ensure LeatherProvider is available
            if (!window.LeatherProvider || typeof window.LeatherProvider.request !== 'function') {
              throw new Error('Leather Wallet is not available');
            }

            // Sending a transfer request to the Leather Wallet
            const resp = await window.LeatherProvider.request('sendTransfer', {
              address: recipient, // Use the recipient variable defined outside the switch statement
              amount: amount.toString(), // Assuming amount is in satoshis. Convert it to a string if needed.
            });

            console.log('Transfer response:', resp);
            resolve(resp); // Resolve the promise with the response from the wallet
          } catch (err) {
            console.error('Error with Leather Wallet payment:', err);
            reject(err); // Reject the promise with the error
          }
        });
      default:
        console.log('No wallet');
    }
  },
};

export default BitcoinService;
