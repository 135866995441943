import React from 'react';

const ErrorPopup = ({ errorMessage, onClose }) => {
  if (!errorMessage) return null;

  return (
    <div className="error-wrapper show">
      <div className="error-box">
        <div>
          <h2 className="error-text">Server Error</h2>
          <p className="error-text">{errorMessage}</p>
          <button className="btn_connect" onClick={onClose}>
            CLOSE
          </button>
        </div>
      </div>
    </div>
  );
};

export default ErrorPopup;
