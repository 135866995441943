import { useEffect, useState } from 'react';
import { BrowserProvider } from 'ethers';

const CHECK_INTERVAL_MS = 1000;
const TIMEOUT_MS = 3000;

export const useMagicEdenProvider = () => {
  const [provider, setProvider] = useState(undefined);
  const [found, setFound] = useState(false);

  useEffect(() => {
    const checkProvider = () => {
      if (window.magicEden?.ethereum) {
        const magicProvider = window.magicEden.ethereum;
        if (magicProvider.isMagicEden) {
          const provider = new BrowserProvider(magicProvider);
          setProvider(provider);
          setFound(true);
          return true;
        }
      }
      return false;
    };

    if (checkProvider()) return;

    const interval = setInterval(() => {
      if (checkProvider()) clearInterval(interval);
    }, CHECK_INTERVAL_MS);

    const timeout = setTimeout(() => {
      if (!found) {
        clearInterval(interval);
      }
    }, TIMEOUT_MS);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [found]);

  return provider;
};
