/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useEffect, useState } from 'react';
import '../css/normalize.css';
import '../css/webflow.css';
import '../css/ordinals.css';

const FAQpopup = ({ showFAQ, onClose, showHoundsLink }) => {
  const [animationClass, setAnimationClass] = useState('');

  const handleHoundsLinkClick = () => {
    window.open('https://magiceden.io/collections/ethereum/0x354634c4621cdfb7a25e6486cca1e019777d841b', '_blank');
  };

  useEffect(() => {
    if (showFAQ) {
      setAnimationClass('show'); // Start scale-up animation
    } else {
      setAnimationClass('hide'); // Start scale-down animation
      setTimeout(() => {
        // Wait for the animation to complete before hiding the component
        if (!showFAQ) setAnimationClass('');
      }, 250); // Match this with your animation duration
    }
  }, [showFAQ]);

  return (
    animationClass && (
      <div className={`faq-section ${animationClass}`}>
        <div className="closebtn">
          <button className="closebtn" onClick={onClose}></button>
        </div>
        <div className="popup-content">
          <div className="page-title">
            <h2 className="faq-text">Frequently Asked Questions</h2>
          </div>
          <div>
            <h3>//What is an Ordinal?</h3>
            <p>
              Ordinals, or Ordinal Theory, is a way to create non-fungible tokens on the Bitcoin blockchain. Most
              commonly it is used to enable Bitcoin NFTs. To learn more visit{' '}
              <a href="https://www.novellabs.xyz/mutantcartel/news/ordinals-explainer" target="_blank" rel="noreferrer">
                this article
              </a>
            </p>
          </div>
          <div>
            <h3>//Why Bring An NFT Collection To Bitcoin?</h3>
            <p>
              To push technical and artistic borders and immortalise the story of the Mutant Hounds as cultural
              artefacts.
            </p>
          </div>
          <div>
            <h3>//What Is Verification?</h3>
            <p>
              Enables you to connect both your ETH and BTC wallets to verify your total holdings, tier eligibility,
              designate your “Mint to” address, and check if you have the funds needed to mint. This process is not
              required but will help prepare you for mint day and dramatically increase the speed at which you can
              purchase your MH//Inscription(s).
            </p>
          </div>
          <div>
            <h3>//How is the Allowlist tiered?</h3>
            <p>There are three possible tiers provided on the collections you hold.</p>
            <p>
              <b>VIP</b> // 3 Max + 1 for each qualifying asset // First Mint Access{' '}
            </p>
            <p>
              <b>Preferred</b> // 3 Max // Second Mint Access
            </p>
            <p>
              <b>Runestone</b> // 2 Max // Third Mint Access
            </p>
            <p>
              <b>Public</b> // 2 Max // Final Mint Access
            </p>
          </div>
          <div>
            <h3>//Who Is Eligible To Mint?</h3>
            <p>
              The mint opens with Mutant Hound holders receiving an advanced window to mint their Ordinal Twin. Access
              will then progress through the tiers in a cumulative fashion. VIP collections are Mutant Hounds, Mutant
              Hounds: Materials, Mutant Cartel VIP Oaths, Nodemonkes, Bitcoin Shrooms, Quantum Cats, and Bitcoin
              Puppets.
              <br />
              Visit the extended{' '}
              <a href="https://www.novellabs.xyz/faq-ordinals" target="_blank" rel="noreferrer">
                FAQ
              </a>{' '}
              for the full list of eligible collections.
            </p>
          </div>
          <div>
            <h3>//Can I Guarantee A Mint?</h3>
            <p>
              The only guaranteed mint is the Mutant Hounds tier. All successive tiers will be first come first served.
              If you’re interested in purchasing a Mutant Hound to mint their twin, you can purchase one on{' '}
              <a
                href="https://magiceden.io/collections/ethereum/0x354634c4621cdfb7a25e6486cca1e019777d841b"
                target="_blank"
                rel="noreferrer"
              >
                Magic Eden
              </a>
              .
            </p>
          </div>
          <div>
            <h3>//More help</h3>
            <p>
              If your questions have not been answered you can raise a question in the discord here or a private ticket
              via{' '}
              <a href="https://discord.com/invite/mutantcartel" target="_blank" rel="noreferrer">
                discord
              </a>
              .
            </p>
          </div>
        </div>
        {showHoundsLink && (
          <div className="btnwrap">
            <button className="btn_connect" onClick={handleHoundsLinkClick}>
              MUTANT HOUNDS ON MAGIC EDEN
            </button>
          </div>
        )}
        <div className="btnwrap">
          <button className="btn_connect" onClick={onClose}>
            CLOSE FAQ
          </button>
        </div>
      </div>
    )
  );
};

export default FAQpopup;
